/**
 *  项目的配置文件
 */
export const apiUrl = 'https://www.bjznzz.com/';//接口请求地址
// export const apiUrl = 'http://127.0.0.1:8001/';
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const chatUrl = 'wss://im.bjznzz.com';//客服地址
// export const chatUrl = 'http://127.0.0.1:8001/';
export const title = 'bjznzz';//浏览器顶部title
// ** copyright *** slodon *** version-v2.9.2 *** date-2021-06-18 ***主版本v2.9


